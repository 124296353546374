<!--
 * @Description: 添加角色弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-03 09:29:27
 * @LastEditTime: 2022-07-01 09:26:46
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 添加角色 弹框 start -->
  <el-dialog
    v-dialogDrag
    :title="type == 'add' ? '添加角色' : '编辑角色'"
    :visible.sync="addRoleDialog"
    width="500px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    v-loading="addRoleLoading"
    :element-loading-background="loadingBackground"
    custom-class="customer-dialog"
  >
    <el-form
      ref="addRoleForm"
      label-width="100px"
      :model="addRoleForm"
      :rules="addRoleRules"
    >
      <el-form-item label="角色名称" prop="roleName">
        <el-input
          v-model.trim="addRoleForm.roleName"
          placeholder="角色名称"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--1" label="角色组" prop="roleGroupId">
        <e6-vr-select
          v-model="addRoleForm.roleGroupId"
          :data="roleTypeList"
          placeholder="角色组"
          title="角色组"
          multiple
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
          clearable
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model.trim="addRoleForm.remark"
          placeholder="备注"
          maxlength="100"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="设置角色权限"
        prop="privilegeIds"
        :inline="false"
        v-if="treeData.length"
      >
        <e6-tree
          style="height:300px"
          v-model="addRoleForm.privilegeIds"
          :list="treeData"
          :is-check="true"
          :is-open="true"
          issingleleaf
          ref="e6tree"
          :selectAndCheck="true"
        ></e6-tree>
      </el-form-item>
      <div class="tip-box" v-else>
        <i class="e6-icon-tips_line"></i>
        <div class="tip-text">
          暂无可分配权限
        </div>
      </div>
    </el-form>

    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button
        type="primary"
        @click="handleSubmit"
        :disabled="!treeData.length"
        >确定</el-button
      >
    </div>
  </el-dialog>
  <!-- 添加角色弹框  end -->
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import {
  addRole,
  roleTree,
  updateRole,
  findDownList,
  getRoleDetail
} from "@/api";
import handleTree from "../mixins/index";
export default {
  name: "addRoleDialog",
  components: {},
  data() {
    return {
      addRoleLoading: false,
      addRoleForm: {
        roleName: "", //角色名称
        roleGroupId: [], //角色组
        remark: "", //备注
        privilegeIds: [] //角色权限集合
      },
      roleTypeList: [], //角色组下拉框
      addRoleRules: {
        roleName: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: ["blur", "change"]
          }
        ],
        privilegeIds: [
          {
            required: true,
            message: "请选择角色权限",
            trigger: ["blur", "change"]
          }
        ],
        roleGroupId: [
          {
            required: true,
            message: "请选择角色组",
            trigger: ["blur", "change"]
          }
        ]
      },
      oldIds: [], //旧权限id
      treeData: []
    }; //角色树
  },
  props: {
    addRoleDialog: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "add"
    },
    detailForm: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [base, handleTree],
  computed: {},
  created() {},
  watch: {
    addRoleDialog: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initData();
          this.getTreeData();
        }
        // if (val && this.type == "edit") {
        //   this.getRoleInfo();
        // } else {
        //   this.getTreeData();
        // }
      }
    }
  },
  methods: {
    // 初始化数据
    async initData() {
      try {
        this.loading = true;
        let promiseList = [findDownList(["roleGroup"])];
        let [roleRes] = await Promise.all(promiseList);
        //获取联系人下拉框
        this.roleTypeList = this.getFreezeResponse(roleRes, {
          path: "data.roleGroup"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //查询角色树
    async getTreeData() {
      try {
        this.addRoleLoading = true;
        let res = await roleTree();
        let treeData = res.data;
        this.handleTreeData(treeData ? [treeData] : []);
        if (this.type == "edit") {
          this.getRoleInfo();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addRoleLoading = false;
      }
    },
    //获取角色权限列表
    async getRoleInfo() {
      let hasParamsRoute = this.getHasParamsRoute();
      let id = hasParamsRoute.params.roleId;
      try {
        let res = await getRoleDetail({ id });
        this.addRoleForm = res.data;
        this.addRoleForm.roleGroupId = this.addRoleForm.roleGroupId.map(
          item => {
            return item.toString();
          }
        );
        this.addRoleForm.privilegeIds = this.addRoleForm.privilegeIds.map(
          item => {
            return item.toString();
          }
        );
        this.oldIds = _.cloneDeep(this.addRoleForm).privilegeIds.map(item => {
          return item.toString();
        });
        // this.getTreeData();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击确定添加
    handleSubmit() {
      this.$refs.addRoleForm.validate(valid => {
        if (valid) {
          if (this.type == "edit") {
            this.updateRole();
          } else {
            this.saveRole();
          }
        }
      });
    },
    //添加角色请求
    async saveRole() {
      try {
        this.addRoleLoading = true;
        let res = await addRole(this.addRoleForm);
        if (res.code == "OK") {
          this.$message.success("角色添加成功");
          this.$emit("refresh");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addRoleLoading = false;
      }
    },
    //编辑角色请求
    async updateRole() {
      try {
        this.addRoleLoading = true;
        let res = await updateRole({
          ...this.addRoleForm,
          privilegeIds: this.addRoleForm.privilegeIds
        });
        if (res.code == "OK") {
          this.$message.success("角色编辑成功");
          this.$emit("refresh");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addRoleLoading = false;
      }
    },
    //关闭弹框
    closeDialog() {
      this.addRoleForm = {
        roleName: "", //角色名称
        roleGroupId: [], //角色组
        remark: "", //备注
        privilegeIds: [] //角色权限集合
      };
      this.$refs.addRoleForm.resetFields();
      this.$emit("handleClose");
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.customer-dialog {
  .el-dialog__body {
    // max-height: 500px;
    overflow-y: auto;
    padding: 20px;
    .el-textarea {
      width: 350px;
    }
  }
}
.tip-box {
  width: 100%;
  display: flex;
  padding-left: 30px;
  align-items: flex-start;
  padding-right: 15px;
  box-sizing: border-box;
  margin-bottom: 30px;
  margin-top: 20px;
  [class^="e6-icon"] {
    font-size: 18px;
  }
  .tip-text {
    color: #666;
    margin-left: 5px;
    line-height: 20px;
  }
}
</style>
