<!--
 * @Description: 角色详情
 * @Author: ChenXueLin
 * @Date: 2021-10-18 14:23:44
 * @LastEditTime: 2022-04-22 14:54:02
 * @LastEditors: LiangYiNing
-->
<!-- 基本信息 -->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content
            :btnType="1"
            btnText="任务单状态:进行中"
            :showTime="false"
          >
            <template v-slot:rightButtons>
              <el-button
                type="primary"
                class="e6-icon-edit_line"
                title="编辑角色"
                @click="handleEdit"
              ></el-button>
              <el-button
                type="primary"
                @click="changeStatus(0)"
                v-if="roleInfo.roleStatus == '正常'"
                >停用</el-button
              >
              <el-button type="primary" @click="changeStatus(1)" v-else
                >启用</el-button
              >
            </template>
          </detail-title-content>
          <!-- 基本信息 start-->
          <template>
            <div class="baseInfo-box">
              <div class="edit-title">基本信息</div>
              <el-form
                label-width="140px"
                style="margin-top:20px;"
                :model="roleInfo"
              >
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="角色ID" prop="roleId">
                      {{ roleInfo.roleId }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="角色名称" prop="roleName">
                      {{ roleInfo.roleName }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="创建人" prop="createBy">
                      {{ roleInfo.createBy }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="创建时间" prop="createTime">
                      {{ roleInfo.createTime }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="备注" prop="remark">
                      {{ roleInfo.remark }}
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <!-- 基本信息 end  -->
          </template>
          <!-- 基本信息  end -->
          <!-- 角色权限  start -->
          <template>
            <div class="baseInfo-box">
              <div class="edit-title">
                权限({{ roleInfo.privilegeIds.length }})
              </div>
              <div class="treeData">
                <e6-tree
                  style="height:530px"
                  v-model="roleInfo.privilegeIds"
                  :list="treeData"
                  :is-check="true"
                  :is-open="true"
                  issingleleaf
                  ref="e6tree"
                  :selectAndCheck="true"
                ></e6-tree>
              </div>
            </div>
          </template>
        </div>
      </section>
    </div>
    <!-- 编辑角色弹框 -->
    <add-role-dialog
      @refresh="getRoleInfo"
      :addRoleDialog="addRoleDialog"
      @handleClose="handleClose"
      type="edit"
      :detailForm="roleInfo"
    ></add-role-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import detailTitleContent from "@/components/detailTitleContent";
import { getRoleDetail, roleTree, roleDisEnable, enableRole } from "@/api";
import { printError } from "@/utils/util";
import AddRoleDialog from "./addRoleDialog.vue";
import handleTree from "../mixins/index";
export default {
  name: "roleDetail",
  data() {
    return {
      loading: false,
      roleInfo: {
        roleId: "", //角色ID
        roleName: "", //角色名称
        createdBy: "", //创建人
        createdTime: "", //创建时间
        remark: "", //备注
        roleStatus: "", //角色状态
        privilegeIds: [] //角色集合
      }, //角色详情
      treeData: [], //角色树
      addRoleDialog: false
    };
  },
  mixins: [base, handleTree],
  computed: {},
  components: { detailTitleContent, AddRoleDialog },
  watch: {},
  created() {
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.roleId = hasParamsRoute.params.roleId;
      if (this.roleId) {
        this.getTreeData();
      }
    },
    //查询角色树
    async getTreeData() {
      try {
        this.loading = true;
        let res = await roleTree();
        let treeData = res.data;
        this.handleTreeData(treeData ? [treeData] : []);
        this.getRoleInfo();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取角色权限列表
    async getRoleInfo() {
      try {
        let res = await getRoleDetail({ id: this.roleId });
        this.roleInfo = res.data;
      } catch (error) {
        printError(error);
      }
    },
    //处理启用禁用
    changeStatus(status) {
      let tipTitle = status == 1 ? "是否确认启用角色？" : "是否确认禁用角色？";
      let title = status == 1 ? "启用角色" : "禁用角色";
      this.$confirm(tipTitle, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (status == 1) {
            this.batchEnable();
          } else {
            this.batchDisEnable();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 启用角色
    async batchEnable() {
      try {
        this.loading = true;
        let res = await enableRole({
          id: this.roleId,
          versionNumber: this.roleInfo.versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("启用成功");
          this.getRoleInfo();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 停用角色
    async batchDisEnable() {
      try {
        this.loading = true;
        let res = await roleDisEnable({
          id: this.roleId,
          versionNumber: this.roleInfo.versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("停用成功");
          this.getRoleInfo();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /*****编辑角色 */
    handleEdit() {
      this.addRoleDialog = true;
      this.type = "edit";
    },

    //关闭弹框
    handleClose() {
      this.addRoleDialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.treeData {
  padding: 20px;
  box-sizing: border-box;
}
</style>
